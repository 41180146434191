import fetch from 'isomorphic-unfetch';
import {matchPath} from 'react-router-dom';

import config from '../config/index';
import {allRoutes} from '../navigation/routes';
import PingIdentityService from './PingIdentityServices';

const OK_STATUS_CODES = [200, 201];

export const BASE_URL = config.services.baseUrl;
export const JSON_URL = config.services.jsonUrl;
export const APPLICATION_ID = config.services.applicationId;

const APPLICATION_JSON_CONTENT_TYPE = 'application/json';

function createCsrfTokenStore() {
  let csrfToken = null;

  return {
    setToken: token => {
      csrfToken = token;
    },
    getToken: () => {
      return csrfToken;
    },
    removeToken: () => {
      csrfToken = null;
    },
  };
}

const csrfTokenStore = createCsrfTokenStore();

export default {
  getBaseUrl() {
    return BASE_URL;
  },

  clearToken() {
    csrfTokenStore.removeToken();
  },

  getToken() {
    return csrfTokenStore.getToken();
  },

  setToken(token) {
    csrfTokenStore.setToken(token);
  },

  isNonAuthUrl(pathname) {
    const isNonAuth =
      pathname === '/' ||
      allRoutes.some(route => !route.isPrivate && matchPath(pathname, route));
    const isInAllRoutes = allRoutes.some(route => matchPath(pathname, route));

    return isInAllRoutes ? isNonAuth : true;
  },

  async handleResponse(res) {
    if (res.status === 429) {
      throw Error(res.statusText);
    }
    const data = await res.json();

    if (OK_STATUS_CODES.includes(res.status)) {
      return data;
    } else {
      if (res.status === 401) {
        if (!this.isNonAuthUrl(location.pathname)) {
          localStorage.setItem('redirect-url-after-login', location.pathname);
          await PingIdentityService.redirectToLogin();
        }
        throw Error(data.error);
      } else if (Array.isArray(data)) {
        if (data.length > 0 && data[0].error) {
          throw Error(data[0].error);
        }
      } else if (res.status === 403) {
        const token = await PingIdentityService.getCsrfToken();
        this.setToken(token);
        throw Error('Your session has timed out, please try again');
      } else if (data.error) {
        throw Error(data.error);
      } else if (res.status === 409) {
        throw data;
      }

      throw Error('Unexpected Error');
    }
  },

  createHeaders(headers = {}) {
    return {
      'Content-Type': APPLICATION_JSON_CONTENT_TYPE,
      'x-csrf-token': csrfTokenStore.getToken(),
      ...headers,
    };
  },

  async makeBodiedRequest(url, method, body, alternativeBaseUrl, headers) {
    let res;
    try {
      res = await fetch((alternativeBaseUrl || BASE_URL) + url, {
        method,
        headers: this.createHeaders(headers),
        body: JSON.stringify(body),
        credentials: 'include',
      });
      return this.handleResponse(res);
    } catch (e) {
      throw Error('An error has occured');
    }
  },
  async get(url, alternativeBaseUrl) {
    let res;

    try {
      res = await fetch((alternativeBaseUrl || BASE_URL) + url, {
        method: 'GET',
        headers: this.createHeaders(),
        credentials: 'include',
      });

      return this.handleResponse(res);
    } catch (e) {
      throw Error('An unxpected error occurred', e);
    }
  },
  async post(url, body, alternativeBaseUrl, headers) {
    return this.makeBodiedRequest(
      url,
      'POST',
      body,
      alternativeBaseUrl,
      headers,
    );
  },
  async patch(url, body, alternativeBaseUrl, headers) {
    return this.makeBodiedRequest(
      url,
      'PATCH',
      body,
      alternativeBaseUrl,
      headers,
    );
  },
  async put(url, body, alternativeBaseUrl, headers) {
    return this.makeBodiedRequest(
      url,
      'PUT',
      body,
      alternativeBaseUrl,
      headers,
    );
  },
  async delete(url, body, alternativeBaseUrl, headers) {
    return this.makeBodiedRequest(
      url,
      'DELETE',
      body,
      alternativeBaseUrl,
      headers,
    );
  },
  async makeBodiedRequestWithTimeout(
    url,
    method,
    body,
    alternativeBaseUrl,
    headers,
    timeout = 8000,
  ) {
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    const res = await fetch((alternativeBaseUrl || BASE_URL) + url, {
      method,
      headers: this.createHeaders(headers),
      body: JSON.stringify(body),
      timeout: timeout,
      signal: controller.signal,
      credentials: 'include',
    });

    clearTimeout(id);

    return this.handleResponse(res);
  },
  async getWithTimeout(url, alternativeBaseUrl, headers, timeout = 8000) {
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);

    const res = await fetch((alternativeBaseUrl || BASE_URL) + url, {
      method: 'GET',
      headers: this.createHeaders(headers),
      timeout: timeout,
      signal: controller.signal,
      credentials: 'include',
    });

    clearTimeout(id);

    return this.handleResponse(res);
  },
  async postWithTimeout(url, body, alternativeBaseUrl, headers, timeout) {
    return this.makeBodiedRequestWithTimeout(
      url,
      'POST',
      body,
      alternativeBaseUrl,
      headers,
      timeout,
    );
  },
  async patchWithTimeout(url, body, alternativeBaseUrl, headers, timeout) {
    return this.makeBodiedRequestWithTimeout(
      url,
      'PATCH',
      body,
      alternativeBaseUrl,
      headers,
      timeout,
    );
  },
  async deleteWithTimeout(url, body, alternativeBaseUrl, headers, timeout) {
    return this.makeBodiedRequestWithTimeout(
      url,
      'DELETE',
      body,
      alternativeBaseUrl,
      headers,
      timeout,
    );
  },
};
