import HttpServices, {JSON_URL, APPLICATION_ID} from './HttpServices';

const LookSeeServices = {
  async getUser() {
    return HttpServices.get('/user/me');
  },
  async checkUser(email) {
    return HttpServices.getWithTimeout(
      `/user/exists?email=${encodeURIComponent(email)}`,
    );
  },
  async login(email, password) {
    const result = await HttpServices.postWithTimeout('/user/login', {
      email,
      password,
    });

    HttpServices.setToken(result.token);

    return result;
  },
  async registerUser(userData) {
    const result = await HttpServices.postWithTimeout('/user/register', {
      first_name: userData.firstName,
      last_name: userData.lastName,
      email: userData.email,
      password: userData.password,
      suburb: userData.suburb,
    });

    HttpServices.setToken(result.token);

    return result;
  },
  async checkToken(token) {
    return HttpServices.getWithTimeout('/token/check', null, {
      'x-access-token': token,
    });
  },
  async propertySearch(query, provider = 'lightstone') {
    return HttpServices.getWithTimeout(
      `/property/find?s=${encodeURIComponent(query)}&provider_id=${provider}`,
    );
  },
  async propertyValuation(query) {
    return HttpServices.get(
      `/property/valuation?property_id=${encodeURIComponent(query)}`,
    );
  },
  async verifyEmail(token) {
    try {
      return await HttpServices.post('/user/email/confirm', {token});
    } catch (e) {
      return e;
    }
  },
  async resendVerificationEmail(email) {
    try {
      return await HttpServices.post('/user/email/verify', {email});
    } catch (e) {
      return e;
    }
  },
  async passwordResetRequest(email) {
    return HttpServices.post('/user/password/forgot', {
      email,
    });
  },
  async resetPassword(token, password) {
    return HttpServices.post('/user/password/reset', {password}, null, {
      'x-access-token': token,
    });
  },
  async changePassword(password) {
    return HttpServices.put('/user/password', {
      password,
    });
  },
  async deactivateAccount() {
    return HttpServices.put('/user/deactivate');
  },
  async claimProperty(property_id) {
    return HttpServices.post('/property/claims', {
      property_id,
    });
  },
  async getEventsNewsfeed(claimId, limit, offset) {
    const url = claimId
      ? `/property/claims/${claimId}/feed`
      : '/property/claims/feed';
    return HttpServices.get(
      `${url}?limit=${encodeURIComponent(limit)}&offset=${encodeURIComponent(
        offset,
      )}`,
    );
  },
  async getClaimedProperties(limit = 100) {
    return HttpServices.getWithTimeout(
      `/property/claims?limit=${encodeURIComponent(limit)}`,
    );
  },
  async deleteClaimedProperty(id) {
    return HttpServices.deleteWithTimeout(`/property/claims/${id}`);
  },
  async getNotifications() {
    return HttpServices.getWithTimeout(`/notifications`);
  },
  async markNotificationRead(data) {
    return HttpServices.post(`/notifications/read`, data);
  },
  async marketplaceSearch(query) {
    // TODO: replace with real route once endpoint is made
    return HttpServices.getWithTimeout(
      `/marketplaces?q=${encodeURIComponent(query)}`,
      JSON_URL,
    );
  },
  async getMarketRecommedations() {
    // TODO: replace with real route once endpoint is made
    return HttpServices.getWithTimeout(`/recommendations`, JSON_URL);
  },
  async getMarketplaceRecentlyViewed() {
    return HttpServices.getWithTimeout(`/marketplace/recently-viewed`);
  },
  async addMarketplaceRecentlyViewed(providerId, externalId) {
    return HttpServices.post(`/marketplace/recently-viewed/line-items`, {
      providerId,
      externalId,
    });
  },

  async getTopProducts() {
    return HttpServices.get(`/products-services/products/top-picks`);
  },

  updateNotificationPreferences(preferences) {
    return HttpServices.put(
      `/applications/${APPLICATION_ID}/settings/notifications/user`,
      {data: preferences},
    );
  },
  async getPreferences(type) {
    return HttpServices.getWithTimeout(
      `/applications/${APPLICATION_ID}/settings/${type}`,
    );
  },
  async getUserPreferences(type) {
    return HttpServices.getWithTimeout(
      `/applications/${APPLICATION_ID}/settings/${type}/user`,
    );
  },
  async getTermsAndConditions(type) {
    const result = await HttpServices.getWithTimeout(
      `/applications/${APPLICATION_ID}/terms/${type}`,
    );

    return {
      type: result.type,
      termId: result.term_id,
      content: result.content,
    };
  },
  async updateTermsAndConditions(type, accepted) {
    return HttpServices.put(
      `/applications/${APPLICATION_ID}/terms/${type}/user`,
      {accepted},
    );
  },
  async postFeedback(feedback, type = 'general') {
    return HttpServices.postWithTimeout(
      `/applications/${APPLICATION_ID}/feedback`,
      {data: feedback, type},
    );
  },
  async postGeneralFeedback(feedback) {
    return HttpServices.postWithTimeout(
      `/applications/${APPLICATION_ID}/contact-us`,
      {data: feedback},
    );
  },
  async getBasket() {
    return HttpServices.get(`/marketplace/cart`);
  },
  async addBasketItem(providerId, externalId, quantity, data) {
    return HttpServices.post(`/marketplace/cart/line-items`, {
      providerId,
      externalId,
      quantity,
      data,
    });
  },
  async deleteBasketItem(lineItemId) {
    return HttpServices.delete(`/marketplace/cart/line-items/${lineItemId}`, {
      lineItemId,
    });
  },
  async updateBasketItem(lineItemId, quantity, data) {
    return HttpServices.patch(`/marketplace/cart/line-items/${lineItemId}`, {
      lineItemId,
      quantity,
      data,
    });
  },
  async getMarketplaceSavedItems() {
    return HttpServices.get(`/marketplace/saved`);
  },
  async saveMarketplaceItem(providerId, externalId, data) {
    return HttpServices.post(`/marketplace/saved/line-items`, {
      providerId,
      externalId,
      data,
    });
  },
  async removeMarketplaceSavedItem(lineItemId) {
    return HttpServices.delete(`/marketplace/saved/line-items/${lineItemId}`, {
      lineItemId,
    });
  },
  async startCheckout(order) {
    // get back { orderId, paymentRedirectUrl }
    return HttpServices.post(`/marketplace/orders`, order);
  },
  async getOrder(orderId) {
    return HttpServices.get(`/marketplace/orders/${orderId}`);
  },
  updateOrderPaymentResult(orderId, result) {
    return HttpServices.post(
      `/marketplace/orders/${orderId}/payment-result/${result}`,
    );
  },
  async getOrders() {
    return HttpServices.get(`/marketplace/orders`);
  },
  async getDemoResponses() {
    return HttpServices.getWithTimeout(
      `/applications/${APPLICATION_ID}/demos/responses`,
    );
  },
  async setDemoViewership(demoId, viewed) {
    return HttpServices.post(
      `/applications/${APPLICATION_ID}/demos/${demoId}/responses`,
      {viewed},
    );
  },
  async completeOrder(orderId) {
    return HttpServices.post(`/marketplace/orders/${orderId}/complete`);
  },
  async cancelOrder(data) {
    return HttpServices.post(
      `/marketplace/orders/${data.orderId}/cancel`,
      data,
    );
  },
  async raiseOrderDispute(orderId, message) {
    return HttpServices.post(`/marketplace/orders/${orderId}/disputes`, {
      message,
    });
  },
  async reportPost(postId) {
    return HttpServices.post(`/moderation/posts/${postId}/reports`);
  },
  async getItem(marketId, categoryId, itemId) {
    return HttpServices.get(
      `/marketplace/markets/${marketId}/categories/${categoryId}/items?itemId=${encodeURIComponent(
        itemId,
      )}`,
    );
  },
  async getAllTriggers() {
    return HttpServices.get(`/triggers`);
  },
  async getTriggerProducts(claimId, triggerId, postalCode) {
    let queryParams = '';
    if (claimId) {
      queryParams = `?claim_id=${encodeURIComponent(claimId)}`;
    } else if (postalCode) {
      queryParams = `?postal_code=${encodeURIComponent(postalCode)}`;
    }
    return HttpServices.get(
      `/property/insights/triggers/${triggerId}/products${queryParams}`,
    );
  },
  async getPaymentRetry(orderId) {
    return HttpServices.post(`/marketplace/orders/${orderId}/payment-retry`);
  },
  async retractCancellation(orderId) {
    return HttpServices.post(`/marketplace/orders/${orderId}/refund/cancel`);
  },
  async getDownloadReport(orderId) {
    return HttpServices.getWithTimeout(
      `/marketplace/orders/${orderId}/document-download-url`,
    );
  },
  async getMessages() {
    return HttpServices.getWithTimeout(
      `/marketplace/messages?operationCode=${encodeURIComponent(
        'chat-message',
      )}&uniqueKey=${encodeURIComponent('referenceId')}`,
    );
  },
  async getMessagesById(id) {
    const res = await HttpServices.getWithTimeout(
      `/marketplace/messages?operationCode=${encodeURIComponent(
        'chat-message',
      )}&referenceId=${encodeURIComponent(id)}`,
    );

    return res.reverse();
  },
  async updateMessageById(id, data) {
    return HttpServices.patchWithTimeout(`/marketplace/messages/${id}`, data);
  },
  async respondToMessage(data) {
    return HttpServices.postWithTimeout(`/marketplace/messages`, data);
  },
  async submitQuoteRequest(data) {
    return HttpServices.post(`/marketplace/quotes/request`, data);
  },
  async getQuotes() {
    return HttpServices.get(`/marketplace/quotes`);
  },
  async declineQuote(quoteId) {
    return HttpServices.postWithTimeout(`/marketplace/quotes/reject`, {
      quoteId,
    });
  },
  async orderQuote(body) {
    return HttpServices.postWithTimeout(
      `/marketplace/quotes/order`,
      body,
      undefined,
      undefined,
      10000,
    );
  },
  async submitSurvey(body) {
    return HttpServices.postWithTimeout(`/survey/responses`, body);
  },
  getSubscriptions() {
    return HttpServices.getWithTimeout('/subscriptions');
  },
  getAppointments() {
    return HttpServices.get(`/marketplace/orders?type=SUBSCRIPTION_ITEM`);
  },
  createSubscription(body) {
    return HttpServices.post(`/subscriptions`, body);
  },
  updateSubscriptionResult(subscriptionId, result) {
    return HttpServices.post(
      `/subscriptions/${subscriptionId}/result/${result}`,
    );
  },
  cancelSubscription(subscriptionId) {
    return HttpServices.post(`/subscriptions/${subscriptionId}/cancel`);
  },
  async searchSuburb(query) {
    return HttpServices.get(`/property/suburb/${encodeURIComponent(query)}`);
  },
  getMarketplaceRules(type) {
    return HttpServices.get(`/marketplace/rules/${type}`);
  },
  addUserSuburb(body) {
    return HttpServices.post(`/user/suburb`, body);
  },
  getUserSuburbs() {
    return HttpServices.get(`/user/suburbs`);
  },
  callMeBack(body) {
    return HttpServices.post(`/marketplace/callback`, body);
  },
  addToWaitingList(body) {
    return HttpServices.post(`/waitinglist/contact/add`, body);
  },
  getContentHubArticles() {
    return HttpServices.get('/content-hub/articles');
  },
  submitSolarApplication(body) {
    return HttpServices.post(`/solar/application`, body);
  },
  getSolarLoanCreditCheck(body) {
    return HttpServices.post(`/solar/qualify`, body);
  },
  submitSolarLoanLead(leadId) {
    return HttpServices.post(`/solar/leads/${leadId}`);
  },
  getPluginToken(context) {
    return HttpServices.get(`/property/plugin/token?context=${context}`);
  },
  getPropertyDetails(addressId, provider, unit_number) {
    return HttpServices.get(
      `/property/details?address_id=${addressId}&provider_id=${provider}&unit_number=${unit_number}`,
    );
  },
  getSolarScore(propertyKey, subplaceId) {
    return HttpServices.get(
      `/solar/score?property_key=${propertyKey}&subplace_id=${subplaceId}`,
    );
  },
  getSolarPackages() {
    return HttpServices.get(`/solar/packages`);
  },
  async getCategories() {
    return HttpServices.getWithTimeout('/products-services/categories');
  },
  getProduct(subcategoryName, itemCode) {
    return HttpServices.get(
      `/products-services/subcategory/${encodeURIComponent(
        subcategoryName,
      )}/product/${encodeURIComponent(itemCode)}`,
    );
  },
  async getSubcategory(subcategoryId) {
    return HttpServices.getWithTimeout(
      `/products-services/category/${subcategoryId}`,
    );
  },
};

export default LookSeeServices;
