import {initializeApp} from 'firebase/app';
import {
  getRemoteConfig,
  fetchAndActivate,
  getAll,
} from 'firebase/remote-config';

import config from '../../config/index';

const firebase = config.firebase;

const defaultConfig = {
  appOnMaintenance: false,
  eventfeed: true,
  guestMode: true,
  community: false,
  insights: true,
  manageAccount: true,
  marketplace: true,
  propertyHub: true,
  popertyHubDefaultUrl: 'solar',
  reportIncorrectProperty: false,
  reportMissingProperty: false,
  uploadImageAndVideo: true,
  homeManagement: false,
  appAdvertisement: true,
  solar: true,
  smartGeyser: true,
  contactUs: true,
  powerPulseInfoUrl: 'https://www.youtube.com/watch?v=CJz9Wj-r-Qc',
  smartGeyserUrl:
    '/marketplace/moneySavers/saveElectricity_moneySavers/SmartGeyser36',
  /*
  The solarHPAConfig object has the following shape:

  {
    interestRate,
    loanTermMonths,
    backUpHours,
    defaultKiloWattRate,
    municipalities: [],
  }
  */
  solarHPAConfig: {},
  /*
  {
   "smartGeyserAlternativeFlow": true,
  "cities": [
      {
        "name": "Cape Town",
        "uri": "https://plentify.io/"
      },
      {
        "name": "Joburg/Pretoria",
        "uri": "https://plentify.io/"
      }
    ]
  }
  */
  smartGeyserConfig: {},
  hpaTestimonialVideoUrl: '',
  smartGeyserCalculator: false,
  solarScore: false,
  /*
  [
     {
      "title": "Where can I learn more?",
      "description": "The answer to the question",
      "linkText": "Get your home power analysis",
      "linkUrl": "#gotohpa"
    }
  ]
   */
  solarScoreFaqs: [],
  mapPluginArguments: {},
  contactUsAssistOptions: [],
  pingLoginModal: false,
  pingRegistrationModal: false,
  solarLoan: true,
  solarLoanConfig: {},
  termsAndConditions: '',
  codeOfConduct: '',
  propertyValuation: false,
};

const parseConfig = object => {
  return Object.assign(
    {},
    ...Object.entries(object).map(([key, value]) => {
      const method =
        typeof defaultConfig[key] === 'boolean' ? 'asBoolean' : 'asString';
      let parsedValue = value[method]();
      if (typeof defaultConfig[key] === 'object') {
        parsedValue = JSON.parse(parsedValue);
      }
      return {
        [key]: parsedValue,
      };
    }),
  );
};

export const initialiseFirebase = () => {
  const firebaseConfig = {
    apiKey: firebase.apiKey,
    authDomain: firebase.authDomain,
    projectId: firebase.projectId,
    storageBucket: firebase.storageBucket,
    messagingSenderId: firebase.messagingSenderId,
    appId: firebase.appId,
    measurementId: firebase.measurementId,
  };

  const app = initializeApp(firebaseConfig);
  const remoteConfig = getRemoteConfig(app);

  remoteConfig.settings = {
    minimumFetchIntervalMillis: firebase.minimumFetchIntervalMillis,
    fetchTimeoutMillis: firebase.fetchTimeMillis,
  };

  remoteConfig.defaultConfig = defaultConfig;

  return remoteConfig;
};

export const fetchConfig = async remoteConfig => {
  await fetchAndActivate(remoteConfig);
  return parseConfig(getAll(remoteConfig));
};
